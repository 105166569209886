<template>
	<h1>Keyboard Events</h1>

	<div class="text-block bump-b-30">
		<pre v-highlightjs><code class="language-javascript">import { useKeyStore } from '@/stores/KeyStore'

export default {
	setup() {
		const keyStore = useKeyStore()
		return { keyStore }
	},
	mounted() {
		// Add multiple action at once.
		const keyActions = {
			any: { action: this.anyKey, cumulate: true },
			// cumulate: false is the default and can be omitted
			'[a|r|t|h|u|r|Enter]': { action: this.multiKey, cumulate: false },
		}
		this.keyStore.addAll(keyActions)

		// Add individual action, cumulated.
		this.keyStore.add('a', this.doA, true)
	},
	beforeUnmount() {
		// Remove multiple action at once.
		const keyActions = {
			any: { action: this.anyKey },
			'[a|r|t|h|u|r|Enter]': { action: this.multiKey },
		}
		this.keyStore.removeAll(keyActions)

		// Remove individual action.
		this.keyStore.remove('a', this.doA)
	},
	methods: {
		anyKey(key) {},
		multiKey(key) {},
		doA(key) {
			alert('Double action')
		}
	},
}
</code></pre>
	</div>

	<div class="text-block bump-b-30">
		<h3>Implementation</h3>
		<ul>
			<li>Use <span class="code">any</span> as key to capture any key press.</li>
			<li>Use <span class="code">[a|b|c|Enter]</span> as key to attach one event to multiple keys.</li>
			<li>
				For meta keys and space bar you can use<span class="code">Control</span>
				<span class="code">Alt</span> <span class="code">Shift</span> <span class="code">Meta</span>
				<span class="code">Space</span>.
			</li>
			<li>
				Arrow keys are named <span class="code">ArrowLeft</span> <span class="code">ArrowUp</span>
				<span class="code">ArrowRight</span> <span class="code">ArrowDown</span>.
			</li>
			<li>
				Pass <span class="code">cumulate</span> as <span class="code">true</span> (3rd parameter in
				addListener) to allow previously assigned listeners on the same key to be executed as well. By
				default, a new actions will override older actions. This prevents the Escape key from closing more
				than one element (eg. a dialog and an edit form).
			</li>
		</ul>
	</div>

	<div class="text-block bump-b-30">
		<h3>Demo</h3>
		<p>
			Press any key. Any letter from "Arthur" plus the Enter key will result in bingo. The letter
			<span class="code">a</span> has a second (cumulated) and a third (non-cumulated) action attached.
		</p>
		<div id="key-display">
			Last key pressed:
			<div class="key">{{ lastKeyPressed }}</div>
			<b v-if="bingo"> BINGO</b>
		</div>
		<br />
		<FormButton @click="removeNonCumulatedAction" value="Remove isolated action" :mR="10" />
		<FormButton @click="removeCumulatedAction" value="Remove cumulated action" />
	</div>
</template>

<script>
// Stores
import { useKeyStore } from '@/stores/KeyStore'

// Components
import FormButton from '@/components/FormButton'

// Internal
import { injectMetaData } from '@/use/MetaData'
import flash from '@/use/FlashAlert'

export default {
	name: 'DocComponentsKeyEvents',
	components: {
		FormButton,
	},
	setup() {
		injectMetaData({
			title: 'Doc: Keyboard Events Component',
		})
		const keyStore = useKeyStore()
		return { keyStore }
	},
	data() {
		return {
			lastKeyPressed: '...',
			bingo: false,
		}
	},
	mounted() {
		const keyActions = {
			any: { action: this.anyKey },
			'[a|r|t|h|u|r|Enter]': { action: this.multiKey },
			a: { action: this.isolatedAction },
		}

		// Multiple actions
		this.keyStore.addAll(keyActions)

		// Individual action (cumulated)
		this.keyStore.add('a', this.cumulatedAction, true)
	},
	beforeUnmount() {
		// Remove multiple listeners at once
		const keyActions = {
			any: { action: this.anyKey },
			'[a|r|t|h|u|r|Enter]': { action: this.multiKey },
		}
		this.keyStore.removeAll(keyActions)

		// Remove individual listener
		this.keyStore.remove('a', this.doA)
	},
	methods: {
		anyKey(key) {
			console.log('-- multi', key)
			this.lastKeyPressed = key
			this.bingo = false
		},
		multiKey(key) {
			console.log('!!anyKey', key)
			this.lastKeyPressed = key
			this.bingo = true
		},
		cumulatedAction(key) {
			this.lastKeyPressed = key
			flash('This is a cumulated action.', {
				persistent: true,
			})
		},
		isolatedAction(key) {
			this.lastKeyPressed = key
			flash(
				[
					'<p>This is an isolated action (default).</p>',
					'<p>For the multiKey "BINGO" action to trigger, remove this isolated action.</p>',
					'<p>Note that actions are executed in reverse order, so the latest action can be isolated from the former actions.</p>',
				].join(''),
				{
					persistent: true,
					html: true,
				}
			)
		},
		removeCumulatedAction() {
			this.keyStore.remove('a', this.cumulatedAction)
		},
		removeNonCumulatedAction() {
			this.keyStore.remove('a', this.isolatedAction)
		},
	},
}
</script>

<style scoped lang="scss">
#key-display {
	background: $black-05;
	padding: 0.2rem;
	border-radius: $br;
}
#key-display .key {
	display: inline-block;
	min-width: 0.2rem;
	padding: 0 0.05rem;
	height: 0.2rem;
	border-radius: 0.04rem;
	background: #fff;
	border: solid 0.01rem $black-10;
	line-height: 0.18rem;
	text-align: center;
}
#key-display b {
	color: $good;
}
</style>
